import {
	getGlobalConfig,
	getHomeConfig, //Needed to to use components.
	transformStaticProps,
} from "@/lib/api";
import Layout from "@/components/layout";
import Markdown from "@/components/markdown";
import logger from "../lib/logger";
import { useEffect } from "react";
import router from "next/router";

export default function Custom404({ config }) {
	useEffect(() => {
		const site = config.configuration.siteConfiguration.site
		logger.warning(site + ": Could not find page with path: " + router.asPath);
	});

	let content;
	if (config.configuration.siteConfiguration?.errorMessage404) {
		content = config.configuration.siteConfiguration?.errorMessage404
	} else {
		content = "# 404\n ## Oj då, sidan kunde inte hittas.\n"
	}
	return (
		<Layout {...config}>
      		<div className="container pt-5">
				<Markdown markdown={content} />
			</div>
		</Layout>
	);
}

export const getStaticProps = async ({ preview, previewData }) => {
	return await transformStaticProps({
		configuration: {...await getGlobalConfig(!!preview, previewData)},
		...(await getHomeConfig(!!preview, previewData)),
		preview: !!preview,
		previewData: previewData ?? null,
	});
};