import React from "react";
import PropTypes from "prop-types";
import styles from "./markdown.module.scss";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";


const Markdown = ({ markdown }) => {
  return (
    <div className={styles.markdown}>
      <ReactMarkdown
          rehypePlugins={[rehypeRaw]}
          remarkRehypeOptions={{allowDangerousHtml: true}}
      >
        {markdown}
      </ReactMarkdown>
    </div>
  );
};

Markdown.propTypes = {
  markdown: PropTypes.string.isRequired,
};

Markdown.defaultProps = {
  markdown: "",
  allowedElements: ["h1", "h2", "p", "ul", "li", "a"],
};

export default Markdown;
